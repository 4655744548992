<template>
  <loading v-if="isLoading" />
  <div v-else class="add-product-container">
    <b-row>
      <b-col
        cols="12"
        :md="
          globalPermission.categories &&
          globalPermission.categories.index &&
          globalPermission.brands &&
          globalPermission.brands.index &&
          globalPermission.catalogs &&
          globalPermission.catalogs.index
            ? '8'
            : '12'
        "
        class="catalog-ddl"
      >
        <label class="typo__label">{{ $t("g.catalog") }}</label>
        <multiselect
          label="name"
          track-by="name"
          :show-labels="false"
          :options="catalogsData"
          v-model="selectedCatalog"
          :placeholder="$t('products/add_product/search_input_placeholder')"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <img
              width="50"
              height="50"
              class="option__image"
              :alt="props.option.name"
              v-if="props.option.main_image"
              :src="props.option.main_image.path"
            />
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }} </span>
              <span class="option__small">{{ props.option.description }}</span>
            </div>
          </template>
        </multiselect>
      </b-col>
      <b-col
        v-if="
          globalPermission.categories &&
          globalPermission.categories.index &&
          globalPermission.brands &&
          globalPermission.brands.index &&
          globalPermission.catalogs &&
          globalPermission.catalogs.index
        "
        cols="12"
        md="4"
        class="d-flex p-1 align-items-end"
      >
        <b-link
          :to="{ name: 'AddCatalog' }"
          role="button"
          class="btn btn-primary suggest-btn"
        >
          <feather-icon icon="PlusIcon" class="text-white" size="16" />
          {{ $t("add_product/suggest_catalog") }}
        </b-link>
      </b-col>
      <b-col cols="12" v-if="selectedCatalogData">
        <catalog-data-card :selectedCatalogProp="selectedCatalogData" />
      </b-col>
      <b-col cols="12">
        <product-data-card
          @handleOverlayLoading="handleOverlayLoading"
          :selectedCatalogData="selectedCatalogData"
        />
      </b-col>
    </b-row>
    <OverlayComponent :isLoading="overlayLoading" />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { BFormInput, BCol, BRow, BLink } from "bootstrap-vue";
import loading from "@/components/shared/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import CatalogDataCard from "@/components/ProductsComponents/CatalogDataCard/index.vue";
import ProductDataCard from "@/components/ProductsComponents/ProductDataCard/index.vue";
export default {
  components: {
    BRow,
    BCol,
    loading,
    BFormInput,
    ProductDataCard,
    CatalogDataCard,
    OverlayComponent,
    BLink,
    Multiselect,
  },
  data() {
    return {
      isLoading: true,
      tagesData: null,
      vendorsData: null,
      catalogsData: null,
      selectedCatalog: null,
      overlayLoading: false,
      selectedCatalogData: null,
      searchIcon: require("@/assets/images/icons/search-icon.svg"),
    };
  },
  beforeMount() {
    this.getCatalogsData();
  },
  methods: {
    async getCatalogsData() {
      try {
        this.isLoading = true;
        const response = await this.$http.get("vendor/catalogs");
        this.catalogsData = response.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getSingleCatalogData(slug) {
      try {
        this.overlayLoading = true;
        const response = await this.$http.get(`vendor/catalogs/${slug}`);
        return response.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    handleOverlayLoading(state) {
      this.overlayLoading = state;
    },
  },
  watch: {
    async selectedCatalog(newValue) {
      if (newValue) {
        this.selectedCatalogData = await this.getSingleCatalogData(
          newValue.slug
        );
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "./index";
</style>
