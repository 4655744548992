<template>
  <div>
    <validation-observer ref="addProductForm">
      <b-form @submit.prevent class="d-flex flex-column">
        <b-card class="product-card-containe r">
          <b-row>
            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/store_name')"
                label-for="Name"
              >
                <validation-provider
                  rules="required"
                  #default="{ errors }"
                  name="Name"
                >
                  <b-form-input
                    disabled
                    v-model="catalogName"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/suffix_name')"
                label-for="Suffix_Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Suffix Name"
                  rules="required"
                >
                  <b-form-input
                    :disabled="catalogId ? false : true"
                    v-model="form_data.suffix_name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Emd Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/sku')" label-for="SKU">
                <b-form-input
                  type="text"
                  :disabled="catalogId ? false : true"
                  v-model="form_data.sku"
                />
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/price')" label-for="Price">
                <validation-provider
                  rules="required"
                  #default="{ errors }"
                  name="Price"
                >
                  <b-form-input
                    type="number"
                    step="0.01"
                    :disabled="catalogId ? false : true"
                    v-model="form_data.price"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/sale')" label-for="Sale">
                <validation-provider #default="{ errors }" name="Sale">
                  <b-form-input
                    type="number"
                    :disabled="catalogId ? false : true"
                    v-model="form_data.sale"
                    step="0.01"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/quantity')"
                label-for="Quantity"
              >
                <validation-provider
                  rules="required"
                  #default="{ errors }"
                  name="Quantity"
                >
                  <b-form-input
                    type="number"
                    :disabled="catalogId ? false : true"
                    v-model="form_data.qty"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/max_quantity')"
                label-for="Maximum Quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Maximum Quantity"
                  :rules="{ required: maxQtyActive }"
                  class="w-100"
                >
                  <b-form-input
                    type="number"
                    v-model="form_data.max_qty"
                    :disabled="catalogId ? false : true"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <!-- min stock   -->
            <b-col class="mb-2" cols="12" sm="6">
              <b-form-group
                :label="$t('g.settings/generalSettings/min_stock')"
                label-for="Min stock"
              >
                <validation-provider #default="{ errors }" name="Min stock">
                  <b-form-input
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :disabled="catalogId ? false : true"
                    v-model="form_data.min_stock"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- **min stock   -->

            <b-col>
              <div class="d-flex">
                <b-form-group label-for="add_product/is_visiable" class="mx-2">
                  <validation-provider
                    #default="{ errors }"
                    name="add_product/is_visiable"
                  >
                    <b-form-checkbox
                      v-model="form_data.is_visible"
                      name="check-button"
                      switch
                    >
                      {{ $t("add_product/is_visiable") }}
                    </b-form-checkbox>

                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label-for="value_added_tax">
                  <validation-provider
                    #default="{ errors }"
                    name="value_added_tax"
                  >
                    <b-form-checkbox
                      v-model="form_data.is_has_vat"
                      name="check-button"
                      switch
                    >
                      {{ $t("g.value_added_tax") }}
                    </b-form-checkbox>

                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>

            <b-col class="mb-2" cols="12">
              <!-- Start Input -->
              <div class="product-images-box">
                <product-main-image-component
                  :image="form_data.mainImage"
                  @set-main-image="handleProductMainImage"
                />
              </div>
              <!-- End Input -->
            </b-col>
          </b-row>
        </b-card>
        <div class="btns-product">
          <b-button
            variant="outline-danger"
            class="align-self-end"
            @click="cancel"
          >
            {{ $t("g.cancel") }}
          </b-button>
          <div style="text-align: end; gap: 20px" class="d-flex flex-wrap">
            <b-button
              type="submit"
              variant="primary"
              class="align-self-end"
              :disabled="catalogId ? false : true"
              @click.prevent="validation()"
            >
              {{ $t("g.products/products_list/add_product") }}
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormSelectOption,
  BFormFile,
  BButton,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import { required, integer } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ProductMainImageComponent from "@/components/ProductsComponents/MainDataTabComponent/MainImageComponent/index.vue";

export default {
  components: {
    ProductMainImageComponent,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BButton,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormCheckbox,
    BForm,
  },
  props: ["selectedCatalogData", "handleOverlayLoading"],
  computed: {
    catalogName() {
      this.form_data.suffix_name = this.selectedCatalogData?.name;
      return this.selectedCatalogData?.name;
    },
    catalogId() {
      return this.selectedCatalogData?.id;
    },
  },
  created() {
    this.getVendorData();
  },
  data() {
    return {
      required,
      integer,
      vendorData: null,
      productMainImage: null,
      form_data: {
        suffix_name: null,
        sku: null,
        max_qty: null,
        min_qty: 1,
        qty: null,
        sale: null,
        price: null,
        min_stock: null,
        mainImage: null,
        is_visible: true,
        is_has_vat: false,
      },
      maxQtyActive: false,
      addExatraIcon: require("@/assets/images/icons/Lead icon.svg"),
      addImageIcon: require("@/assets/images/icons/add-image-icon.svg"),
      addProductImagesIcon: require("@/assets/images/icons/add-product-images.svg"),
    };
  },
  methods: {
    cancel() {
      for (let index = 0; index < this.form_data.length; index++) {
        this.form_data[index] = null;
      }
      this.$router.push({ name: "products" });
    },
    async getVendorData() {
      try {
        const response = await this.$http.get("vendor/my-vendor");
        this.vendorData = response.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    handleProductMainImage({ file }) {
      this.form_data.mainImage = file;
    },
    async handleSubmitProduct() {
      try {
        this.$emit("handleOverlayLoading", true);
        const formData = new FormData();
        if (this.form_data.sale === null) {
          formData.append("sale", 0);
          delete this.form_data.sale;
        }

        for (let item in this.form_data) {
          if (this.form_data[item] !== null) {
            if (item === "mainImage") {
              formData.append("images[0][file]", this.form_data[item]);
              formData.append("images[0][is_main]", 1);
            } else if (item === "is_has_vat" || item === "is_visible") {
              formData.append(item, this.form_data[item] ? 1 : 0);
            } else if (item === "sale") {
              if (this.form_data.sale && this.form_data.sale !== "0.00") {
                formData.append(item, this.form_data[item]);
              }
            } else if (item === "max_qty") {
              this.form_data.max_qty &&
                formData.append(item, this.form_data[item]);
            } else if (item === "sku") {
              this.form_data.sku && formData.append(item, this.form_data[item]);
            } else {
              formData.append(item, this.form_data[item]);
            }
          }
        }
        formData.append("catalog_id", this.catalogId);
        const vendor = localStorage.getItem("vendorId");

        formData.append("vendor_id", vendor.id);
        if (this.form_data.min_qty === null) {
          formData.append("min_qty", 1);
        }
        const res = await this.$http.post("vendor/products", formData);
        if (res.status === 200 || res.status === 201) {
          this.$helpers.makeToast("success", res.data.message);

          this.$router.push({ name: "products" });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.$emit("handleOverlayLoading", false);
      }
    },
    validation() {
      this.$refs.addProductForm.validate().then((success) => {
        if (success) {
          this.handleSubmitProduct();
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
