var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('loading'):_c('div',{staticClass:"add-product-container"},[_c('b-row',[_c('b-col',{staticClass:"catalog-ddl",attrs:{"cols":"12","md":_vm.globalPermission.categories &&
        _vm.globalPermission.categories.index &&
        _vm.globalPermission.brands &&
        _vm.globalPermission.brands.index &&
        _vm.globalPermission.catalogs &&
        _vm.globalPermission.catalogs.index
          ? '8'
          : '12'}},[_c('label',{staticClass:"typo__label"},[_vm._v(_vm._s(_vm.$t("g.catalog")))]),_c('multiselect',{attrs:{"label":"name","track-by":"name","show-labels":false,"options":_vm.catalogsData,"placeholder":_vm.$t('products/add_product/search_input_placeholder')},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(props.option.name))])])]}},{key:"option",fn:function(props){return [(props.option.main_image)?_c('img',{staticClass:"option__image",attrs:{"width":"50","height":"50","alt":props.option.name,"src":props.option.main_image.path}}):_vm._e(),_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(props.option.name)+" ")]),_c('span',{staticClass:"option__small"},[_vm._v(_vm._s(props.option.description))])])]}}]),model:{value:(_vm.selectedCatalog),callback:function ($$v) {_vm.selectedCatalog=$$v},expression:"selectedCatalog"}})],1),(
        _vm.globalPermission.categories &&
        _vm.globalPermission.categories.index &&
        _vm.globalPermission.brands &&
        _vm.globalPermission.brands.index &&
        _vm.globalPermission.catalogs &&
        _vm.globalPermission.catalogs.index
      )?_c('b-col',{staticClass:"d-flex p-1 align-items-end",attrs:{"cols":"12","md":"4"}},[_c('b-link',{staticClass:"btn btn-primary suggest-btn",attrs:{"to":{ name: 'AddCatalog' },"role":"button"}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"PlusIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t("add_product/suggest_catalog"))+" ")],1)],1):_vm._e(),(_vm.selectedCatalogData)?_c('b-col',{attrs:{"cols":"12"}},[_c('catalog-data-card',{attrs:{"selectedCatalogProp":_vm.selectedCatalogData}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('product-data-card',{attrs:{"selectedCatalogData":_vm.selectedCatalogData},on:{"handleOverlayLoading":_vm.handleOverlayLoading}})],1)],1),_c('OverlayComponent',{attrs:{"isLoading":_vm.overlayLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }