var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"addProductForm"},[_c('b-form',{staticClass:"d-flex flex-column",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',{staticClass:"product-card-containe r"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/store_name'),"label-for":"Name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false : null},model:{value:(_vm.catalogName),callback:function ($$v) {_vm.catalogName=$$v},expression:"catalogName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/suffix_name'),"label-for":"Suffix_Name"}},[_c('validation-provider',{attrs:{"name":"Suffix Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.catalogId ? false : true,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.suffix_name),callback:function ($$v) {_vm.$set(_vm.form_data, "suffix_name", $$v)},expression:"form_data.suffix_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/sku'),"label-for":"SKU"}},[_c('b-form-input',{attrs:{"type":"text","disabled":_vm.catalogId ? false : true},model:{value:(_vm.form_data.sku),callback:function ($$v) {_vm.$set(_vm.form_data, "sku", $$v)},expression:"form_data.sku"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/price'),"label-for":"Price"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","step":"0.01","disabled":_vm.catalogId ? false : true,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.price),callback:function ($$v) {_vm.$set(_vm.form_data, "price", $$v)},expression:"form_data.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/sale'),"label-for":"Sale"}},[_c('validation-provider',{attrs:{"name":"Sale"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","disabled":_vm.catalogId ? false : true,"step":"0.01","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.sale),callback:function ($$v) {_vm.$set(_vm.form_data, "sale", $$v)},expression:"form_data.sale"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/quantity'),"label-for":"Quantity"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","disabled":_vm.catalogId ? false : true,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.qty),callback:function ($$v) {_vm.$set(_vm.form_data, "qty", $$v)},expression:"form_data.qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('add_product/max_quantity'),"label-for":"Maximum Quantity"}},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Maximum Quantity","rules":{ required: _vm.maxQtyActive }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","disabled":_vm.catalogId ? false : true,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.max_qty),callback:function ($$v) {_vm.$set(_vm.form_data, "max_qty", $$v)},expression:"form_data.max_qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/min_stock'),"label-for":"Min stock"}},[_c('validation-provider',{attrs:{"name":"Min stock"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":_vm.catalogId ? false : true},model:{value:(_vm.form_data.min_stock),callback:function ($$v) {_vm.$set(_vm.form_data, "min_stock", $$v)},expression:"form_data.min_stock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',[_c('div',{staticClass:"d-flex"},[_c('b-form-group',{staticClass:"mx-2",attrs:{"label-for":"add_product/is_visiable"}},[_c('validation-provider',{attrs:{"name":"add_product/is_visiable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.form_data.is_visible),callback:function ($$v) {_vm.$set(_vm.form_data, "is_visible", $$v)},expression:"form_data.is_visible"}},[_vm._v(" "+_vm._s(_vm.$t("add_product/is_visiable"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"value_added_tax"}},[_c('validation-provider',{attrs:{"name":"value_added_tax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.form_data.is_has_vat),callback:function ($$v) {_vm.$set(_vm.form_data, "is_has_vat", $$v)},expression:"form_data.is_has_vat"}},[_vm._v(" "+_vm._s(_vm.$t("g.value_added_tax"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"product-images-box"},[_c('product-main-image-component',{attrs:{"image":_vm.form_data.mainImage},on:{"set-main-image":_vm.handleProductMainImage}})],1)])],1)],1),_c('div',{staticClass:"btns-product"},[_c('b-button',{staticClass:"align-self-end",attrs:{"variant":"outline-danger"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("g.cancel"))+" ")]),_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"text-align":"end","gap":"20px"}},[_c('b-button',{staticClass:"align-self-end",attrs:{"type":"submit","variant":"primary","disabled":_vm.catalogId ? false : true},on:{"click":function($event){$event.preventDefault();return _vm.validation()}}},[_vm._v(" "+_vm._s(_vm.$t("g.products/products_list/add_product"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }